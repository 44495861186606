import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { SectionHeader, TypedText } from 'components/molecules';
import { HeroShaped } from 'components/organisms';
import { Icon } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  fontWeight900: {
    fontWeight: 900,
  },
  leftSideContent: {
    '& .section-header__cta-container': {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        '& .section-header__cta-item-wrapper': {
          width: '100%',
          '&:last-child': {
            marginLeft: 0,
            marginTop: theme.spacing(1),
          },
          '& .MuiButtonBase-root': {
            width: '100%',
          },
        },
      },
    }
  },
  heroShaped: {
    '& .hero-shaped__image': {
      backgroundColor: theme.palette.alternate.main,
    },
    [theme.breakpoints.down('sm')]: {
      '& .hero-shaped__image': {
        position: 'relative',
      },
      '& .hero-shaped__wrapper': {
        flexDirection: 'column',
      },
    },
  },
  imageAnimation: {
    background: `url("./home-hero-bg-light.png")`,
    backgroundRepeat: 'repeat',
    backgroundAttachment: 'scroll',
    backgroundSize: '400px auto',
    animation: `$slideshow 50s linear infinite`,
    width: '600%',
    height: '600%',
    backgroundColor: theme.palette.alternate.dark,
    top: '-25%',
    left: '-100%',
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      backgroundSize: '800px auto',
    }
  },
  imageAnimationDark: {
    background: `url("./home-hero-bg-light.png")`,
  },
  '@keyframes slideshow': {
    '0%': {
      transform: 'rotate(-13deg) translateY(-25%)',
    },
    '100%': {
      transform: 'rotate(-13deg) translateY(-80%)',
    },
  },
}));

const Hero = ({ themeMode = 'light', className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();

  const title = (
    <Typography variant="h2" component="span" className={classes.fontWeight900}>
      Ищешь знакомств?
      <br />
      <TypedText
        component="span"
        variant="h3"
        color="secondary"
        className={classes.fontWeight900}
        typedProps={{
          strings: [
            'скачивай...',
            'и общайся...',
            'без рекламы',
            'без фейков',
            'без свайпов',
            'без ограничений!',
            '',
            'а заработать?',
            'можно и это...',
            'собирай ⭐ в чате',
            'приглашай знакомых',
            'меняй ⭐ на рубли...',
            'вывод на карту',
            'или кошелек!',
            '',
            'а еще?',
            'сделай тупой мем...',
            'запости в соц сети...',
            'собирай лайки',
            'обменивай на рубли!',
          ],
          typeSpeed: 50,
          loop: true,
        }}
      />
    </Typography>
  );

  const subtitle = 'Хватит тратить время на бессмысленные фейки, свайпы и рекламу! Начни знакомится по-номому - скачай СтарЧат!';

  
  const appleButton = (
    <Button
      size="large"
      variant="contained"
      color="primary"
      component="a"
      href='https://apps.apple.com/ru/app/starchat/id1478690076'
    >
      <Icon
        fontIconClass="fa fa-apple" 
        fontIconColor="white" 
        size="small"
        className="card-job-minimal__arrow"
      />
      Айфон
    </Button>
  );

  
  
  const buyButton = (
    <Button
      size="large"
      variant="contained"
      color="primary"
      component="a"
      href='https://play.google.com/store/apps/details?id=best.supercool.booq'
    >
      <Icon
        fontIconClass="fa fa-android" 
        fontIconColor="white" 
        size="small"
        className="card-job-minimal__arrow"
      />
      Андройд
    </Button>
  );

  const leftSideContent = (
    <SectionHeader
      title={title}
      subtitle={subtitle}
      align="left"
      titleProps={{
        variant: 'h2',
        color: 'textPrimary',
      }}
      ctaGroup={[buyButton, appleButton]}
      data-aos="fade-right"
      disableGutter
      className={classes.leftSideContent}
    />
  );
  return (
    <div className={className} {...rest}>
      <HeroShaped
        className={classes.heroShaped}
        leftSide={leftSideContent}
        rightSide={(
          <div
            className={clsx(
              classes.imageAnimation,
              themeMode === 'dark' ? classes.imageAnimationDark: '',
            )}
          />
        )}
      />
    </div>
  );
};

export default Hero;
