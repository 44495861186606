import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import {  SectionHeader } from 'components/molecules';
import { Icon } from 'components/atoms';

const useStyles = makeStyles(() => ({
  fontWeight900: {
    fontWeight: 900,
  },
}));

const GetStarted = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const title = "Начни знакомства заново...";
  const subtitle = 'Скачай бесплатно и расскажи другим! ';
  
  return (
    <div className={className} {...rest}>
      <SectionHeader
      title={title}
      subtitle={subtitle}
      align="left"
      titleProps={{
        variant: 'h2',
        color: 'textPrimary',
      }}
      ctaGroup={[buyButton, appleButton]}
      data-aos="fade-right"
      disableGutter
      // className={classes.leftSideContent}
    />
    </div>
  );
};


const appleButton = (
  <Button
    size="large"
    variant="contained"
    color="primary"
    component="a"
    href='https://apps.apple.com/ru/app/starchat/id1478690076'
  >
    <Icon
      fontIconClass="fa fa-apple" 
      fontIconColor="white" 
      size="small"
      className="card-job-minimal__arrow"
    />
    Айфон
  </Button>
);



const buyButton = (
  <Button
    size="large"
    variant="contained"
    color="primary"
    component="a"
    href='https://play.google.com/store/apps/details?id=best.supercool.booq'
  >
    <Icon
      fontIconClass="fa fa-android" 
      fontIconColor="white" 
      size="small"
      className="card-job-minimal__arrow"
    />
    Андройд
  </Button>
);

export default GetStarted;
