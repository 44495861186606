import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { NoSsr, Grid, Button, useMediaQuery, Typography } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { LearnMoreLink } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  quickStartSection: {
    '& .section-header__cta-container': {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        '& .section-header__cta-item-wrapper': {
          width: '100%',
          '&:last-child': {
            marginLeft: 0,
            marginTop: theme.spacing(1),
          },
          '& .MuiButtonBase-root': {
            width: '100%',
          },
        },
      },
    }
  },
  button: {
    marginTop: theme.spacing(1),
    alignSelf: 'flex-start',
  },
  fontWeight900: {
    fontWeight: 900,
  },
  editor: {
    paddingLeft: `${theme.spacing(2)}px !important`,
    paddingRight: `${theme.spacing(2)}px !important`,
    borderRadius: theme.spacing(1/2),
    width: '100%',
  },
}));

const QuickStart = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const docsButton = (
    <Button size="large" variant="outlined" color="primary" component="a" href="/documentation">
      Documentation
    </Button>
  );

  const buyButton = (
    <Button
      size="large"
      variant="contained"
      color="primary"
      component="a"
      href="/home"
    >
      Get Started
    </Button>
  );

  return (
    <div className={className} {...rest}>
      <Grid container justify="space-between" spacing={4}>
        {/* <Grid item xs={12}>
          <Grid container justify="space-between" spacing={isMd ? 4 : 2}>
            <Grid item xs={12} md={6} data-aos={'fade-right'}>
              <SectionHeader
                title="Less code. More speed"
                subtitle="theFront is a simple, modular and accessible component library that gives you the building blocks you need to build your React applications."
                ctaGroup={[docsButton, buyButton]}
                align={isMd ? 'left' : 'center'}
                disableGutter
                titleVariant="h3"
                titleProps={{ className: classes.fontWeight900 }}
                className={classes.quickStartSection}
              />
            </Grid>
            <Grid item container alignItems="center" xs={12} md={6} data-aos={'fade-left'}>
              <NoSsr>
                <SyntaxHighlighter language="javascript" style={vs2015} className={classes.editor}>
                  {`
> $ npm install

// Everything installed!


> $ npm start

// LiveReload started. Opening localhost:3000
                `}
                </SyntaxHighlighter>
              </NoSsr>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item xs={12}>
          <Grid container justify="space-between" spacing={isMd ? 4 : 2} direction={isMd ? 'row': 'column-reverse'}>
            {/* <Grid item xs={12} container alignItems="center" md={6} data-aos={'fade-right'}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography color="primary" variant="h4" gutterBottom>300+</Typography>
                  <Typography color="textPrimary" variant="body1">
                    300 + component compositions, which will help you to build any page easily.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography color="primary" variant="h4" gutterBottom>30+</Typography>
                  <Typography color="textPrimary" variant="body1">
                    30 + stand-alone, fully customizable components designed by following Atomic Methodologies.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography color="primary" variant="h4" gutterBottom>45+</Typography>
                  <Typography color="textPrimary" variant="body1">
                    45 + landing and supported pages to Build a professional website.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography color="primary" variant="h4" gutterBottom>99%</Typography>
                  <Typography color="textPrimary" variant="body1">
                    99% of our customers rated 5-star our themes over 5 years..
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item xs={12} md={6} data-aos={'fade-left'}>
            <SectionHeader
                title="Как заразботать звезды?"
                subtitle="Приглашай молодых людей и девушек которые ищут знакомств и получай звездные бонусы!"
                align="left"
                disableGutter
                titleVariant="h3"
                titleProps={{ className: classes.fontWeight900 }}
              />
            <SectionHeader
                title="Как заразботать звезды?"
                subtitle="Просто проси их добавить тебя как знакомого по твоему айди в поиске (свой айди ты тоже найдешь в поиске). Первый добавленный пользователем знакомый после регистрации считается пригласившим и получает бонус согласно тарифу:"
                align="left"
                disableGutter
                titleVariant="h3"
                titleProps={{ className: classes.fontWeight900 }}
              />
              <SectionHeader
                title=""
                subtitle="⭐100 за первого приглашенного"
                align="left"
                disableGutter
                titleVariant="h3"
                titleProps={{ className: classes.fontWeight900 }}
              />
              <SectionHeader
                title=""
                subtitle="⭐80 за второго приглашенного"
                align="left"
                disableGutter
                titleVariant="h3"
                titleProps={{ className: classes.fontWeight900 }}
              />
              <SectionHeader
                title=""
                subtitle="⭐60 за третьего приглашенного"
                align="left"
                disableGutter
                titleVariant="h3"
                titleProps={{ className: classes.fontWeight900 }}
              />
              <SectionHeader
                title=""
                subtitle="⭐40 за четвертого приглашенного"
                align="left"
                disableGutter
                titleVariant="h3"
                titleProps={{ className: classes.fontWeight900 }}
              />
              <SectionHeader
                title=""
                subtitle="⭐20 за каждого последующего..."
                align="left"
                disableGutter
                titleVariant="h3"
                titleProps={{ className: classes.fontWeight900 }}
              />

              <SectionHeader
                title=""
                subtitle="Если пользователь тебя добавил, а звезды не начислились..."
                align="left"
                disableGutter
                titleVariant="h3"
                titleProps={{ className: classes.fontWeight900 }}
              />
              <LearnMoreLink
                title={'посмотрите почему это возможно в часто задаваемых вопросах...'}
                variant="body1"
                href="help"
                className={classes.button}
              />

            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default QuickStart;
